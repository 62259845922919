import React from 'react';

import Ourteam from "./Ourteam"



function About() {
  return (
    <div>


      <header className="">
        <div className="  container mx-auto px-4">

          <h1 className="font-poppins  flex justify-center sm:text-6xl text-4xl font-semibold mb-4    text-primary">About LekkerInvoice</h1>
          <p className=" flex justify-center text-xl  font-roboto text-primary">Empowering businesses to manage their finances more efficiently and effectively.</p>
        </div>
      </header>

      <main className="container mx-auto px-16 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h2 className="font-poppins text-2xl font-semibold mb-4  text-primary">Our Story</h2>
            <p className="font-roboto font-normal text-primary">LekkerInvoice was founded in 2015 by John Smith, a serial entrepreneur with a passion for finance and technology. After struggling to find an invoicing solution that met his needs, John decided to create his own platform. Since then, LekkerInvoice has grown from a small startup to a leading invoicing platform with thousands of customers worldwide. </p>
          </div>
          <div>
            <h2 className="text-2xl font-semibold mb-4 font-poppins text-primary">Our Team</h2>
            <p className="font-roboto font-base text-primary">Our team at LekkerInvoice is made up of experienced developers, designers, and financial experts who are passionate about helping businesses succeed. We believe in transparency, simplicity, and innovation, and we are committed to providing our customers with the best possible invoicing experience. </p>
          </div>
        </div>
      </main>
      <Ourteam />

    </div>
  );
}

export default About;