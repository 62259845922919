import React from "react";
import LekkerImg1 from "../images/ppp.png"
import LekkerImg2 from "../images/jpe.png"
import LekkerImg3 from "../images/payment.png"
import LekkerImg4 from "../images/voice.jpg"
import LekkerImg5 from "../images/few.png"
import LekkerImg6 from "../images/payment.png"


export const Features = () => {
  const features = [
    {
      name: 'Schedule recurring invoices',
      description: 'Creating recurring invoices for transactions that occur at regular interval , so you do not have to create them individually every time .',
    },
    {
      name: 'Sign your invoices',
      description: 'Use your digital signature to prevent invoice forgery or tampering.LekkerInvoice Sign intergration ensures the highest level of security,compliant with ESIGN and eIDAS e-signature laws.',
    },
    {
      name: 'Invoice in multiple currencies',
      description:
        'With multi-currency invoicing support from LekkerInvoice, you can bill your customers in the right currency of their location.',
    },
    { name: 'Customize your invoicing templates', description: 'With customizable templates from LekkerInvoice, you can extend your brand from your website to your invoices for a consistant professional image' },
    { name: 'Schedule invoices', description: 'Need to send out an invoice on a future date? LekkerInvoice lets you schedule your invoices to be automatically sent to your customers on the date you have chosen' },
    { name: 'Set expiry date', description: 'Add expiry dates to your estimates to encourage customers to accempt them promptly and keep the sale process moving' }
  ]
  return (
    <div className="">
      <div>
        <div className="mx-auto max-w-7xl px-6 mb-16 sm:py-2 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-2">
          <div className='px-16 rounded-3xl'>
            <div className=''>
              <h1 className="font-poppins text-6xl sm:text-5xl text-primary font-semibold max-w-full md:py-2 ">
                Better invoicing method
              </h1>
              <p className='mt-6 text-lg font-roboto font-extrabold leading-9 text-primary'>
                LekkerInvoice is a feature-rich invoicing software.Whether you're invoicing online or recording offline payments, LekkerInvoice has you recovered.
              </p>
              <div class="flex items-center px-1 py-10 mt-10">
                <a href="https://app.lekkerinvoice.co.za/register" class="group flex items-center justify-center rounded-full bg-myCustomcolor py-3 px-8 text-lg text-primary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-600">
                  <span className="items-center font-openSans font-semibold text-xl flex mr-2">Get started
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#0d0d4a" class="w-6 h-6 group-hover:translate-x-1.5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="container relative py-20 flex mt-16 sm:mt-10 lg:mt-0 lg:flex-grow lg-flex-shrink-0 ">
            <img src={LekkerImg2} className='rounded-3xl w-full object-cover' alt="Pic 2" />
          </div>
        </div>

        <div class="relative bg-tertiary group hover:bg-primary max-w-screen m-auto py-5 flex justify-center">
          <div class="flex flex-wrap">
            <div class="mx-2.5">
              <div class="my-8">
                <a href="https://app.lekkerinvoice.co.za/register" class="group flex items-center text-lg font-medium text-fs-16 md:text-fs-18" data-test-id="internal-link-">
                  <span class="text-2xl font-grotesk font-semibold text-primary text-center relative transition duration-300 ease-in-out group-hover:text-myCustomcolor group-hover:opacity-80">
                    Transform your invoicing with LekkerInvoice
                  </span>
                  <svg class="relative ml-2 transition-transform transform translate-x-0 duration-300 ease-in-out group-hover:translate-x-2 group-hover:opacity-80 group-hover:fill-myCustomcolor inline" width="30" height="18" viewBox="0 0 30 18" fill="#0d0d4a" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <polygon id="Fill-1" transform="translate(-54.000000, -156.000000)" points="69.8116938 156 77.6760673 163.464653 54 163.464653 54 166.535347 77.6760673 166.535347 69.8113552 174 74.5176558 174 84 165 74.5176558 156"></polygon>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>

        <section id="how-it-works" className="bg-teal-50 py-12">
          <div className="max-w-6xl mx-auto px-6 sm:px-6 lg:px-8">
            <h2 className="font-poppins text-6xl sm:text-5xl text-primary font-semibold leading-9 text-center tracking-tight mb-8">How it works</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="p-6 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#0000FF" className="mx-auto w-16 h-16 mb-6">
                  <path d="M10 2.5a7.5 7.5 0 100 15 7.5 7.5 0 000-15zM7.5 7.75a.75.75 0 01.75-.75h3.5a.75.75 0 010 1.5h-3.5a.75.75 0 01-.75-.75zm0 4a.75.75 0 01.75-.75h3.5a.75.75 0 010 1.5h-3.5a.75.75 0 01-.75-.75zm0 4a.75.75 0 01.75-.75h3.5a.75.75 0 010 1.5h-3.5a.75.75 0 01-.75-.75z" />
                </svg>
                <h3 className="text-xl font-extrabold text-gray-900 mb-2">1</h3>
                <p className="mt-6 text-lg font-roboto font-extrabold leading-7 text-primary mb-4">Create an account to start using our invoicing service.</p>
              </div>
              <div className="p-6 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#0000FF" className="mx-auto w-16 h-16 mb-6">
                  <path d="M4 5h12v7H4V5z" />
                  <path fillRule="evenodd" d="M1 3.5A1.5 1.5 0 012.5 2h15A1.5 1.5 0 0119 3.5v10a1.5 1.5 0 01-1.5 1.5H12v1.5h3.25a.75.75 0 010 1.5H4.75a.75.75 0 010-1.5H8V15H2.5A1.5 1.5 0 011 13.5v-10zm16.5 0h-15v10h15v-10z" clipRule="evenodd" />
                </svg>
                <h3 className="text-xl font-extrabold text-gray-900 mb-2">2</h3>
                <p className=" mt-6 text-lg font-roboto font-extrabold leading-7 text-primary mb-4">Create an invoice with our easy-to-use invoice builder.</p>

              </div>
              <div className="p-6 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#0000FF" className="mx-auto w-16 h-16 mb-6">
                  <path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z" />
                  <path fillRule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clipRule="evenodd" />
                </svg>
                <h3 className="text-xl font-extrabold text-gray-900 mb-2">3</h3>
                <p className="mt-6 text-lg font-roboto font-extrabold leading-7 text-primary mb-4">Create an invoice with our easy-to-use invoice builder.</p>
              </div>
            </div>
          </div>
        </section>



        <div className="bg-white">
          <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center py-8">
              <h2 className="font-poppins text-6xl sm:text-5xl text-primary font-semibold text-center tracking-tight leading-8">
                Streamline Your Invoicing Process With LekkerInvoice.
              </h2>
            </div>
            <div className=" p-16">
              <div className="grid grid-cols-3 rounded-2xl  gap-4">
                <div className=" rounded-2xl overflow-hidden">
                  <div className="flex bg-tertiary justify-center">
                    <img
                      className="h-96 mx-auto object-cover"
                      src="https://www.pinelabs.com/img/blog/online-payments_mode-banner.jpg"
                      alt=""
                    />
                  </div>
                  <div className="p-6 mb-8">
                    <h3 className="text-xl text-center font-opensans font-semibold text-primary">Accept payments</h3>
                    <p className="mt-6 text-lg font-roboto font-extrabold leading-7 text-primary text-center">Get real-time updates on payment status and stay on top of your finances.</p>
                  </div>
                </div>
                <div className=" rounded-2xl overflow-hidden">
                  <div className="flex bg-tertiary justify-center">
                    <img
                      className="h-96 mx-auto object-cover"
                      src="https://www.datanami.com/wp-content/uploads/2018/09/insight_engine_shutterstock_Photon-photo.jpg"
                      alt="Insights"
                    />
                  </div>
                  <div className="p-6 mb-8">
                    <h3 className="text-xl text-center font-opensans font-semibold text-primary">Get insights</h3>
                    <p className="mt-6 text-lg font-roboto font-extrabold leading-7 text-primary text-center">Track your sales, get insights, and make data-driven decisions to grow your business.</p>
                  </div>
                </div>
                <div className=" rounded-2xl overflow-hidden">
                  <div className="flex bg-tertiary justify-center">
                    <img
                      className="h-96 mx-auto object-cover"
                      src={LekkerImg1}
                      alt="Invoicing"
                    />
                  </div>
                  <div className="p-6 mb-b">
                    <h3 className="text-xl text-center font-opensans font-semibold text-primary">Send invoices</h3>
                    <p className="mt-6 text-lg font-roboto font-extrabold leading-7 text-primary text-center">Create and send professional invoices to get paid faster.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white">
          <section aria-labelledby="features-heading" className="relative">
            <div className="p-12 aspect-h-2 aspect-w-3 overflow-hidden sm:aspect-w-5 lg:aspect-none lg:absolute lg:h-full lg:w-1/2 lg:pr-4 xl:pr-16">
              <img
                src={LekkerImg3}
                alt=""
                className="h-screen w-full lg-flex-shrink-0 object-cover object-center rounded-3xl"
              />
            </div>
            <div className="py-12 mx-auto max-w-2xl px-4 sm:px-6 sm:pb-32 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-4 lg:px-8">
              <div className="lg:col-start-2">
                <h2 id="features-heading" className="font-poppins text-6xl sm:text-5xl text-primary font-semibold text-left tracking-tight">
                  Make all payments online
                </h2>
                <p className="mt-6 text-lg font-roboto font-extrabold text-primary tracking-tight">With LekkerInvoice, creating and sending invoices is just a matter of a few clicks</p>
                <dl className="font-roboto font-normal mt-6 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
                  {features.map((feature) => (
                    <div key={feature.name}>
                      <dt className="mt-2 font-roboto font-extrabold text-md text-primary">{feature.name}</dt>
                      <dd className="text-md font-roboto font-normal mt-2 text-primary">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </section>
        </div>




        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="col-span-1 p-16">
            <h2 className="font-poppins text-6xl sm:text-5xl text-primary font-semibold text-left tracking-tight ">Makes your card machine smarter</h2>
            <p className="mt-6 text-lg font-roboto font-extrabold leading-9 text-primary font-xl">
              Add products, services, and categories, and manage your prices and taxes all in one place. Any changes you make on the website will be immediately reflected on your invoices, making it easier to keep your records up-to-date.
            </p>
            <div class="flex items-left py-14">
              <a href="https://app.lekkerinvoice.co.za/register" class="group flex items-center justify-center rounded-full bg-myCustomcolor py-3 px-8 text-lg text-primary  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-600">
                <span className="items-center font-openSans font-semibold text-xl flex mr-2">Get started <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 group-hover:translate-x-1.5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                </svg>
                </span>
              </a>
            </div>
          </div>
          <div className="grid grid-cols-3 pt-8 pr-24">
            <div className="relative col-span-2">
              <img
                src="https://d6xcmfyh68wv8.cloudfront.net/blog-content/uploads/2020/09/life-cycle-of-card-payments-1.png"
                alt="Pic 1"
                className="absolute rounded-lg top-8 left-6 w-80 h-96 object-cover"
              />
            </div>
            <div className="flex flex-col space-y-2">
              <img
                src="https://www.pesapal.com/media/2091/sabi-pos.jpg"
                alt="Pic 2"
                className="w-full h-48 mt-8 rounded-lg object-cover"
              />
              <img
                src="https://www.paisabazaar.com/wp-content/uploads/2022/11/Featured-Image-12-768x511.jpg"
                alt="Pic 3"
                className="w-full h-48 rounded-lg object-cover"
              />
            </div>
          </div>
        </div>
        <div className="flex mx-auto h-screen max-w-6 sm:max-w-7xl sm:px-6 lg:px-4 lg:max-w-7xl lg:grid lg:grid-cols-2 gap-8 lg:gap-0 w-full">
          <div className=" grid grid-cols-3 pt-24 pr-16">
            <div className="relative col-span-2">
              <img
                src={LekkerImg5}
                alt="Pic 1"
                className="absolute rounded-lg top-8 left-6 w-80 h-96 object-cover"
              />
            </div>
            <div className="flex flex-col space-y-1 mt-4">
              <img
                src="https://www.goodreturns.in/img/2020/01/creditcard-23-1461389394-1578371326.jpg?30052023034215"
                alt="Pic 2"
                className="w-full h-48 mt-4 rounded-lg object-cover"
              />
              <img
                src={LekkerImg4}
                alt="Pic 3"
                className="w-full h-48 mb-4 rounded-lg object-cover"
              />
            </div>
          </div>
          <div className='relative mr-12 mt-32'>
            <div className='pl-6 sm:pl-12'>
              <h1 className='font-poppins text-6xl sm:text-5xl text-primary font-semibold text-left tracking-tight '>A reliable invoicing method</h1>
              <p className='mt-6 text-lg font-roboto font-extrabold leading-7 text-primary pt-4  mb-4'>With LekkerInvoice software, you can easily create professional invoices on your computer or mobile device and send them to your customers via email. Plus, you can use LekkerInvoice's integration with various payment gateways to accept online payments, making it easy for your customers to pay you right from their devices.</p>
            </div>
            <div className="flex items-center px-6  sm:px-12 mt-12">
              <a href="https://app.lekkerinvoice.co.za/register" className="group flex items-center justify-center rounded-full bg-myCustomcolor py-3 px-8 text-lg text-primary border focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-600">
                <span className="font-openSans font-semibold text-xl items-center flex mr-2">Get started
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 group-hover:translate-x-1.5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>




      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="col-span-1 p-16">
          <h2 className="font-poppins text-6xl sm:text-5xl text-primary font-semibold text-left tracking-tight">Upgrade your invoicing experience</h2>
          <p className="mt-6 text-lg font-roboto font-extrabold leading-7 text-primary pt-12">Our invoice financing solution offers fast, flexible cash advances to eligible customers. Check for financing offers on our website and pay back the advance via a small percentage of future transactions.</p>
          <div class="flex items-left py-4 mt-8">
            <a href="https://app.lekkerinvoice.co.za/register" class="group flex items-center justify-center rounded-full bg-myCustomcolor py-3 px-8 text-lg text-primary  border focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-600">
              <span className="font-openSans font-semibold text-xl items-center flex mr-2">Get started <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 group-hover:translate-x-1.5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
              </svg>
              </span>
            </a>
          </div>
        </div>
        <div className="grid grid-cols-3 pt-8 pr-24">
          <div className="relative col-span-2">
            <img
              src="https://www.withvector.com/wp-content/uploads/2022/04/5e599b67ef5a3620272886a6_AUTOMATED-INVOICING.png"
              alt="Pic 1"
              className=" absolute rounded-lg top-8 left-6 w-80 h-96 object-auto"
            />
          </div>
          <div className="flex flex-col space-y-1">
            <img
              src={LekkerImg6}
              alt="Pic 2"
              className="w-full h-48 mt-8 rounded-lg object-cover"
            />
            <img
              src="https://cdn.mooninvoice.com/image/images/invoice-tracking.webp"
              alt="Pic 3"
              className="w-full h-48 rounded-lg object-cover"
            />
          </div>
        </div>
      </div>

      <div class="p-16 bg-tertiary">
        <div className="lg:text-center py-8">
          <h2 className="font-poppins text-6xl sm:text-5xl text-primary font-semibold text-center tracking-tight">
            Effortlessly Manage Your Invoicing with LekkerInvoice.
          </h2>
        </div>


        <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div class="rounded-3xl max-w-full pb-1/1 mt-5 h-full">
            <div class="relative overflow-hidden flex items-end max-w-full h-full rounded-3xl bg-no-repeat bg-cover w-full" style={{ backgroundImage: "url('https://fatora.io/wp-content/uploads/2022/01/Security-concerns.jpg')" }}>
              <div class="absolute bottom-0 left-0 right-0 rounded overflow-hidden width-full ease-in-out duration-500 !h-full bg-gradient-to-t from-gray-900 via-gray-900/30"></div>
              <div class="z-10 md:transform flex md:justify-between flex-col h-full p-9 justify-between">
                <div>
                  <h1 class="font-poppins text-6xl mb-10 font-semibold tracking-tight md:text-4xl lg:text-5xl font-grotesk text-myCustomcolor w-60">User Friendly Interface</h1>
                  <p class="mt-6 text-lg font-roboto font-extrabold leading-7 md:text-xl text-white">LekkerInvoice improves the quality of data by reducing the risk of errors and inconsistencies that can occur with manual data entry.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="rounded-3xl max-w-full pb-1/1 mt-5 h-full">
            <div class="relative overflow-hidden flex items-end max-w-full h-full rounded-3xl bg-no-repeat bg-cover w-full" style={{ backgroundImage: "url('https://fatora.io/wp-content/uploads/2022/01/Security-concerns.jpg')" }}>
              <div class="absolute bottom-0 left-0 right-0 rounded overflow-hidden width-full ease-in-out duration-500 !h-full bg-gradient-to-t from-gray-900 via-gray-900/30"></div>
              <div class="z-10 md:transform flex md:justify-between flex-col h-full p-9 justify-between">
                <div>
                  <h1 class="font-poppins text-6xl mb-10 font-semibold tracking-tight md:text-4xl lg:text-5xl font-grotesk text-myCustomcolor w-60">Hassle Free Billing</h1>
                  <p class="mt-6 text-lg font-roboto font-extrabold max-w-full  md:text-xl leading-7 text-white">Our invoicing software makes payments easir for businesses by automating the invoicing and billing proces.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="rounded-3xl max-w-full pb-2/3 mt-5 h-full">
            <div class="relative overflow-hidden flex items-end max-w-full h-full rounded-3xl bg-no-repeat bg-cover w-full" style={{ backgroundImage: "url('https://fatora.io/wp-content/uploads/2022/01/Security-concerns.jpg')" }}>
              <div class="absolute bottom-0 left-0 right-0 rounded overflow-hidden width-full ease-in-out duration-500 !h-full bg-gradient-to-t from-gray-900 via-gray-900/30"></div>
              <div class="z-10 md:transform flex md:justify-between flex-col h-full p-9 justify-between">
                <div>
                  <h1 class="font-poppins text-left mb-10 font-semibold tracking-tight md:text-4xl lg:text-5xl text-2xl w-56 md:w-60 text-myCustomcolor">24/7 Customer Support</h1>
                  <p class="mt-6 text-lg font-roboto font-extrabold leading-7 max-w-full md:text-xl text-white ">Our support team is available round the clock to assist you with any issues or questions you may have about our invoicing software.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
};
