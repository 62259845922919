import LekkerImg from "../images/home.png"
import About from './About';
import { Features } from '../pages/Features';
import { CheckIcon } from '@heroicons/react/20/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faYoutube } from '@fortawesome/free-brands-svg-icons'
import BlogPosts from "./BlogPosts";



function Home() {
  return (
    <div>
        <div className="relative isolate py-10 overflow-y-hidden overscroll-none" >
       <div className="ml-16 max-w-8xl sm:py- lg:flex lg:items-center lg:gap-x-10 lg:px-8 ">
      
        <div className=" max-w-xl lg:mx-0 lg:flex-auto   ">
        <a href="https://www.youtube.com/watch?v=VIDEO_ID_HERE" className='py-5  font-roboto font-bold text-lg text-primary underline'>
        <FontAwesomeIcon icon={faYoutube} className='text-primary  px-2 '/>
    Watch Demo Video
  </a>
      
          <h1 className="font-poppins py-10  text-6xl text-left  font-semibold tracking-tight   text-primary sm:text-5xl aspect-w-2 ">
            Get paid and grow.</h1>
          <p className=" text-lg font-roboto font-extrabold leading-9 text-primary">
          Say goodbye to invoicing headaches with our online invoicing software. LekkerInvoice brings you a digital record of all your invoices that you can access at any time.  </p>
      
          <div className="mt-5 flex items-center  py-5">
            <a href="https://app.lekkerinvoice.co.za/register" className=" group flex items-center justify-center rounded-full bg-myCustomcolor py-4 px-4 text-md text-primary  text-semibold font-openSans">
              <span className="flex mr-2 items-center font-openSans font-semibold text-xl ">Start for free<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 mr-3 group-hover:translate-x-1.5"><path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" /></svg></span>
            </a>
            <a href="mailto:team@lekkerinvoice.co.za" className="text-xl font-opensans  leading-6 text-primary underline underline-offset-8 ml-12">Contact Us</a>
          </div>
          <div className='flex mt-5 mb-0 gap-x-3  text-primary'>
        <ul className='flex space-x-4 mb-0'>
    
        <li className="flex  "><CheckIcon className='w-5 h-5  text-blue-600 '/>Try for free</li>
        <li className="flex "><CheckIcon className='w-5 h-5  text-blue-600 '/>No credit card required</li>
        <li className="flex "><CheckIcon className='w-5 h-5  text-blue-600 '/>Cancel anytime</li>
 </ul>
     
        </div>
        </div>
        <div className="container  py-10 flex sm:mt-10 lg:mt-0 lg:flex-grow lg-flex-shrink-0 ">

      <div className="relative isolate max-w-8xl max-h-8xl " >
        <img src={LekkerImg} alt="laptop image" className=" h-full w-full object-cover" />
          </div>
        </div>
      </div>
      </div>
      <Features />
      <About />
      <BlogPosts />
    </div>
  );
}

export default Home;