import { useState, useEffect } from "react";
import axios from "axios";

function BlogPosts() {
  const [posts, setPosts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    axios
      .get("https://lekkercommerce.co.za/wp-json/wp/v2/posts?_embed&per_page=24")
      .then((response) => setPosts(response.data))
      .catch((error) => console.log(error));
  }, []);

  function calculateReadingTime(content) {
    const wordsPerMinute = 200;
    const words = content.replace(/<\/?[^>]+(>|$)/g, "").split(" ").length;
    const readingTime = Math.ceil(words / wordsPerMinute);
    return readingTime;
  }

  function handlePrev() {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  }

  function handleNext() {
    if (currentIndex <= (posts.length / 2) - 2) {
      setCurrentIndex(currentIndex + 1);
    }
  }

  function Post({ post }) {
    const readingTime = calculateReadingTime(post.content.rendered);

    return (
      <div className="post w-96 flex-shrink-0">
        <div className="post-image-wrapper w-full">
          <a href={`https://lekkercommerce.co.za/blog/${post.slug}`}>
            <img
              className="h-64 w-full object-cover rounded-3xl"
              src={post._embedded["wp:featuredmedia"][0].source_url}
              alt={post.title.rendered}
            />
          </a>
        </div>
        <div className="flex flex-col h-40 mb-3">
          <div className="flex items-center mb-1 mt-4">
            <p className="text-md font-roboto font-extrabold text-primary inline-block">
              {new Date(post.date).toLocaleDateString(undefined, { month: 'long', year: 'numeric' })}
            </p>
            <span className="text-md font-roboto font-extrabold text-primary mx-4 inline-block">.</span>
            <p className="text-md font-roboto font-extrabold text-primary text-md inline-block">
              {calculateReadingTime(post.content.rendered)} min read
            </p>
          </div>
          <a href={`https://lekkercommerce.co.za/blog/${post.slug}`}>
            <h1 className="text-xl font-opensans font-semibold text-primary">{post.title.rendered}</h1>
          </a>
          <div className="overflow-hidden mt-3">
            <p className="text-md font-roboto font-extrabold text-primary">
              {post.excerpt.rendered.replace(/<\/?[^>]+(>|$)/g, "")}
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="blog-posts p-3 mb-3">
      <div className="container mx-auto px-12">
        <div className="text-center mx-auto max-w-3xl py-6">
          <h2 className="font-poppins text-6xl sm:text-5xl text-primary font-semibold text-center tracking-tight leading-8">
            Insights from our community
          </h2>
          <p className="mt-6 text-lg font-roboto font-extrabold leading-9 text-primary">
            Hundreds of thousands of South African businesses already use
            LekkerInvoice to get paid and grow. Read their stories on the
            LekkerInvoice Blog.
          </p>
        </div>
        <div className="relative mt-4 overflow-hidden">
          <button
            className="absolute z-10 flex items-center justify-center w-12 h-12 rounded-full bg-primary dark:bg-gray-800/30 group-hover:bg-white/50 top-1/3 left-4 transform -translate-y-1/2"
            onClick={handlePrev}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-white">
              <path fill-rule="evenodd" d="M20.25 12a.75.75 0 01-.75.75H6.31l5.47 5.47a.75.75 0 11-1.06 1.06l-6.75-6.75a.75.75 0 010-1.06l6.75-6.75a.75.75 0 111.06 1.06l-5.47 5.47H19.5a.75.75 0 01.75.75z" clip-rule="evenodd" />
            </svg>
          </button>
          <button
            className="absolute z-10 flex items-center justify-center w-12 h-12 rounded-full bg-primary dark:bg-gray-800/30 group-hover:bg-white/50 top-1/3 right-4 transform -translate-y-1/2"
            onClick={handleNext}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-white">
              <path fill-rule="evenodd" d="M3.75 12a.75.75 0 01.75-.75h13.19l-5.47-5.47a.75.75 0 011.06-1.06l6.75 6.75a.75.75 0 010 1.06l-6.75 6.75a.75.75 0 11-1.06-1.06l5.47-5.47H4.5a.75.75 0 01-.75-.75z" clip-rule="evenodd" />
            </svg>
          </button>
          <div className="flex flex-nowrap space-x-4 transition-transform duration-500" style={{ transform: `translate(-${currentIndex * 48}rem)` }}>
            {posts.map((post) => (
              <Post post={post} key={post.id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogPosts;