import Header from "../src/components/pages/Header";
import Home from './components/pages/Home';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Features } from './components/pages/Features';
import PricingPlans from "./components/pages/PricingPlans";
import About from '../src/components/pages/About';
import Pricing from "../src/components/pages/Pricing";
import Terms from "../src/components/pages/terms";
import Privacy from "../src/components/pages/privacy";
import Refund from "../src/components/pages/refund";


function App() {
  return (
    <div>
      <Header />
   
   
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/features" element={<Features />} />
          <Route path="/about" element={<About />} />
          <Route path="/refund" element={<Refund />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/pricing" element={<Pricing />} />
        </Routes>
        
      
      <Footer />
    </div>
  );
}

export default App;