import React from 'react';
import { CheckIcon } from '@heroicons/react/20/solid'



function Pricing() {

  const tiers = [
    {
      name: 'Lite',
      id: 'tier-lite',
      href: '#',
      price: { monthly: 'R199' },
      description: 'Great if you do all your invoicing yourself.',
      features: [
        'Create up to 100 invoices per month.',
        'Add unlimited clients.',
        'Connect your Yoco account.',
        'No collaborators.'
      ],
    },
  ]
  const items = [
    {
      name: 'Small Business',
      id: 'item-small business',
      href: '#',
      price: { monthly: 'R499' },
      description: 'Perfect if you invoice together as a team.',
      features: [
        'Create up to 300 invoices per month.',
        'Invite unlimited team members.',
        'Add unlimited clients.',
        'Connect your Yoco account.',
      ],
    },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }



  return (
    <div className='mt-0'>
      <div className="flex flex-col md:flex-row">

        <div className="md:flex-auto container bg-primary rounded-br-[400px] mx-auto max-w-2xl lg:mx-0 lg:flex-auto px-5 ">
          <h1 className='text-8xl font-poppins text-left font-semibold text-tertiary pl-20 pt-16 pb-16 tracking-tight aspect-w-2 sm:text-7xl leading-8'>No payment fees.<br></br>Payments are direct to you.</h1>
          <p className=' font-roboto font-semibold text-3xl text-myCustomcolor  pt-5 pl-20 '>Get 2-months free with annual billing</p>
          <div className=" mt-2 pl-20  py-10 flex items-center gap-x-10">
            <a href="https://app.lekkerinvoice.co.za/register" class=" group flex items-center justify-center rounded-full bg-myCustomcolor py-4 px-12 text-featuresText text-primary ">
              <span className="font-bold flex font-roboto items-center mr-2">Start for free<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" font-extrabold w-6 h-6 group-hover:translate-x-1.5"><path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" /></svg></span>
            </a>
          </div>
        </div>
        <div className="lg:pt-32 container relative aspect-h-2 ">
          <img class="absolute inset-0 right-0 top-0 w-full bg-fixed object-cover sm:h-80 lg:absolute lg:h-full rounded-bl-[200px] border-2" src="https://static01.nyt.com/images/2015/09/29/business/29cards-web/29cards-web-articleLarge.jpg?quality=75&auto=webp&disable=upscale" alt="" />
        </div>
      </div>

      <div className=''>
        <div className=' flex justify-center'>
          <div className=" max-w-7xl ">
            <div className="bg-tertiary py-10 px-6 mx-auto max-w-7xl text-center">
              <h1 className=" font-poppins text-6xl sm:text-5xl font-semibold  leading-7 text-center text-primary animate-bounce">No hidden costs with LekkerInvoice</h1>
              <p className="mt-2 font-roboto text-center font-semibold tracking-tight text-primary sm:text-3xl">
                No transaction fees. No card required!
              </p>
              <p className=" font-roboto font-medium mx-auto mt-2 mb-10 max-w-2xl text-center  tracking-widest  text-2xl leading-7 text-primary">
                Get 2-months free with annual billing.
              </p>
            </div>

            <div className="mt-8 flex flex-col md:flex-row px-5 mx-auto">  
    <div className="rounded-[100px] flex flex-col md:flex-row mx-12 mt-3">
       <div className="max-w-7xl px-10">
         <div className="mt-2 mb-6 grid lg:grid-cols-2 md:grid-cols-1 gap-x-80 sm:gap-20">
                    <div className='bg-primary rounded-3xl w-96 h-full'>
                      {tiers.map((tier) => (
                        <div
                          key={tier.id}
                          className={classNames(' ring-myCustomcolor rounded-3xl p-8 ')}
                        >
                          <h3
                            id={tier.id}
                            className={classNames('text-paragraphText text-myCustomcolor mb-5 font-extrabold leading-8')}
                          >
                            {tier.name}
                          </h3>
                          <img src="https://www.getaplano.com/static/c69688c2fcc79d1e78287ea5a9c65fd3/aef42/core-option-img.png" className=" mb-7 animate-wiggle" alt="" />
                          <p className="mt-4 text-featuresText leading-6 text-myCustomcolor font-extrabold">{tier.description}</p>
                          <p className="mt-6 flex items-baseline gap-x-1">
                            <span className="text-titleText  font-bold tracking-tight text-white">R199</span>
                            <span className="text-titleText font-semibold leading-6 text-white">/mo</span>
                          </p>
                          <a
                            href="https://beta.lekkerinvoice.co.za/register"
                            aria-describedby={tier.id}
                            className={classNames(' text-xl text-primary font-opensans ring-1 ring-inset ring-myCustomcolor bg-myCustomcolor mt-6 block rounded-full py-2 px-3 text-center  font-semibold leading-7 ')}
                          >
                            Start Free
                          </a>
                          <ul role="list"
                            className="mt-8 space-y-3 text-featuresText leading-6 text-tertiary">
                            {tier.features.map((feature) => (
                              <li key={feature} className="flex gap-x-1">
                                <CheckIcon className="h-6 w-5 flex-none  fill-myCustomcolor" aria-hidden="true" />
                                {feature}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                    <div className='bg-primary rounded-3xl w-96 h-full'>
                      {items.map((item) => (
                        <div
                          key={item.id}
                          className={classNames(' ring-myCustomcolor rounded-3xl p-8')}
                        >
                          <h3 id={item.id}
                            className={classNames('text-paragraphText text-myCustomcolor mb-6 font-extrabold leading-10')}
                          >
                            {item.name}
                          </h3>
                          <img src="https://www.getaplano.com/static/a7bb67cfa92ea8d1c8929b08cb053a91/aef42/light-option-img.png" className='animate-wiggle' alt="" />
                          <p className="mt-4 text-featuresText leading-6 text-myCustomcolor font-extrabold">{item.description}</p>
                          <p className="mt-6 flex items-baseline gap-x-1">
                            <span className="text-titleText font-bold tracking-tight text-white">R199</span>
                            <span className="text-titleText font-semibold leading-6 text-white">/mo</span>
                          </p>
                          <a href="https://beta.lekkerinvoice.co.za/register"
                            aria-describedby={item.id}
                            className={classNames('text-xl text-primary ring-1 ring-inset ring-myCustomcolor bg-myCustomcolor  mt-6 block rounded-full py-2 px-3 text-center font-opensans font-semibold leading-6 ')}
                          >
                            Start Free
                          </a>
                          <ul role="list" className="mt-8 space-y-3 text-featuresText leading-6 text-tertiary">
                            {item.features.map((feature) => (
                              <li key={feature} className="flex gap-x-3">
                                <CheckIcon className="h-6 w-5 flex-none  fill-myCustomcolor" aria-hidden="true" />
                                {feature}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  );
}

export default Pricing;