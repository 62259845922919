import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function Header() {
  const [backgroundColor, setBackgroundColor] = useState('bg-primary'); // default background color
  const [textColor, setTextColor] = useState('text-white'); // default text color
  const [textColorHover, setTextColorHover] = useState('text-myCustomcolor'); // default text color on hover when scrolling

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setBackgroundColor('bg-tertiary'); // set background color to a new color for scrolling
        setTextColor('text-black'); // set text color to a new color for scrolling
        setTextColorHover('text-black'); // set text color on hover to a new color for scrolling
      } else {
        setBackgroundColor('bg-primary'); // set background color to the default color when not scrolling
        setTextColor('text-white'); // set text color to the default color when not scrolling
        setTextColorHover('text-myCustomcolor'); // set text color on hover to the default color when not scrolling
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`sticky top-0 z-30 ${backgroundColor}`}>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          <div className="">
            <img className="block h-8 w-auto " src="https://emoji.slack-edge.com/T5NAQ62LC/lekkerinvoice/95f785fbc903315b.png" alt="Your Company"/>
          </div>
          <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex space-x-4">
                <a href="/" className={`rounded-md px-3 py-2 text-sm font-medium ${textColor} hover:${textColorHover} hover:underline underline-offset-8`}>Home</a>
                <Link to="/features" className={`rounded-md px-3 py-2 text-sm font-medium ${textColor} hover:${textColorHover} hover:underline underline-offset-8`}>Features</Link>
                <Link to="/pricing" className={`rounded-md px-3 py-2 text-sm font-medium ${textColor} hover:${textColorHover} hover:underline underline-offset-8`}>Pricing</Link>
                <a href="mailto:team@lekkerinvoice.co.za" className={`rounded-md px-3 py-2 text-sm font-medium ${textColor} hover:${textColorHover} hover:underline underline-offset-8`}>Contact Us</a>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <a href="https://app.lekkerinvoice.co.za/login" className={`inline-flex items-center md:flex text-sm font-medium ${textColor} hover:${textColorHover} hover:underline underline-offset-8 pr-10 pl-10`}>Login</a>
            <a href="https://app.lekkerinvoice.co.za/register" className="group flex items-center justify-center rounded-full bg-myCustomcolor py-3 px-2 text-md text-semibold font-openSans hover:text-black">
              <span className="flex ml-3 text-sm font-openSans text-center">Get started <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-5 mr-3 group-hover:translate-x-1.5"><path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" /></svg></span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;